import moment from 'moment-timezone'
import { isToday } from './utils'

export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0)
      .toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) {
    return ''
  }

  const nameArray = value.split(' ')
    .slice(0, 2)
  const initials = nameArray.map(word => word.charAt(0)
    .toUpperCase())
    .join('')

  return initials
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
}) => {
  if (!value) return value

  return new Intl.DateTimeFormat('pt-BR', formatting).format(new Date(value))
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = {
    month: 'short',
    day: 'numeric',
  }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = {
      hour: 'numeric',
      minute: 'numeric',
    }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export function convertStringToDate(dateString) {
  return moment.tz(dateString, 'ddd MMM D YYYY HH:mm:ss', 'America/Sao_Paulo')
    .format('DD/MM/YY HH:mm')
}

export function mascaraDataBr(date) {
  if (!date) return ''
  return moment(date)
    .format('DD/MM/YY HH:mm')
}

export function converteDataString(dateString) {
  if (dateString) {
    const novaData = new Date(dateString)
    return moment(novaData)
      .format('YYYY-MM-DD')
  }
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const limpaMoeda = valor => {
  // Remove espaços e o símbolo 'R$'
  let valorLimpo = valor.replace(/\s|R\$/g, '')
  // Substitui a vírgula por ponto
  valorLimpo = valorLimpo.replace(',', '.')
  // Converte para Number
  console.log(valorLimpo, 'valor')
  return Number(valorLimpo)
}

export const formatarParaMoedaBrasileira = valor => {
  // Converte para número se for string
  if (typeof valor === 'string') {
    valor = parseFloat(valor.replace(',', '.'))
  }

  // Garante duas casas decimais
  let valorFormatado = valor.toFixed(2)
    .toString()

  // Substitui ponto por vírgula
  valorFormatado = valorFormatado.replace('.', ',')

  // Adiciona pontos nos milhares
  valorFormatado = valorFormatado.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // Adiciona prefixo de moeda
  return `R$ ${valorFormatado}`
}
