import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },

  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/subdisciplinas', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`subdisciplinas/${item.id.uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('nome', itemData.nome)

      return new Promise((resolve, reject) => {
        axios
          .post('/subdisciplinas', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('nome', itemData.nome)
        const listaTarefas = itemData.tarefasFavoritas.map(item => {
          if (item.id) {
            return item.id
          }
          return item.uuid
        })
        formData.append('valor', itemData.valor)
        formData.append('descricao', itemData.descricao)
        console.log(listaTarefas, itemData)

        Object.keys(listaTarefas)
          .forEach(chave => {
            console.log(chave, listaTarefas[chave])
            formData.append('tarefasFavoritas[]', listaTarefas[chave])
          })

        formData.append('_method', 'PUT')

        axios
          .post(`subdisciplinas/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/subdisciplinas/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
