<template>
  <div :class="`tag ${estiloTag} ${corTexto}`"> <span>{{ textoBotao }}</span> </div>
</template>

<script>

export default {

  props: {
    texto: String,
    bgColor: {
      type: String,

    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cores: [
        {
          class: 'cor__padrao',
          hexa: '#336195',
          descricao: 'Padrão',
        },
        {
          class: 'cor__rosa',
          hexa: '#BA529D',
          descricao: 'Rosa',
        },
        {
          class: 'cor__azul__escuro',
          hexa: '#407abb',
          descricao: 'Azul escuro',
        },
        {
          class: 'cor__vermelho',
          hexa: '#ea5455',
          descricao: 'Vermelho',
        },
        {
          class: 'cor__azul__claro',
          hexa: '#00ade7',
          descricao: 'Azul claro',
        },
        {
          class: 'cor__laranja',
          hexa: '#ff9f43',
          descricao: 'Laranja',
        },
        {
          class: 'cor__verde__agua',
          hexa: '#00ABB9',
          descricao: 'Verde água',
        },
        {
          class: 'cor__amarelo',
          hexa: '#FFB83D',
          descricao: 'Amarelo',
        },
        {
          class: 'cor__verde',
          hexa: '#73e264',
          descricao: 'Verde',
        },
        {
          class: 'cor__cinza',
          hexa: '#ced4da',
          descricao: 'Cinza',
        },
        {
          class: 'cor__marrom',
          hexa: '#6E4C0F',
          descricao: 'Marrom',
        },
        {
          class: 'cor__preto',
          hexa: '#4b4b4b',
          descricao: 'Preto',
        },
      ]
    };
  },

  computed: {

    estiloTag() {
      return this.cores.find((cor) => cor.hexa === this.bgColor).class
    },
    corTexto() {
      return this.dark ? 'text-dark' : 'text-white'
    },
    textoBotao() {
      return this.texto ? this.texto : '-'
    }

  },


};
</script>

<style lang="scss" scoped>
.tag {

  min-height: 15px;
  padding: 5px 10px;
  border-radius: 10rem;
  font-size: 0.60rem;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 5px;
  font-weight: 600;
  cursor: default;
}

.cor__azul__escuro {
  background-color: rgba(64, 122, 187, 0.5);

  span {
    color: rgb(29, 102, 184);

  }

}

.cor__azul__claro {
  background-color: rgb(0, 173, 231, 0.5);

  span {
    color: rgb(5, 149, 221);
  }
}

.cor__laranja {
  background-color: rgb(255, 159, 67, 0.5);

  span {
    color: rgb(250, 132, 21) !important;

  }
}

.cor__vermelho {
  background-color: rgb(234, 84, 85, 0.5);

  span {
    color: rgb(228, 40, 40);
  }
}

.cor__verde {
  background-color: rgb(115, 226, 100, 0.5);

  span {
    color: rgb(74, 207, 57);
  }
}

.cor__verde__agua {
  background-color: rgb(0, 171, 185, 0.5);

  span {
    color: rgb(2, 148, 161);
  }
}

.cor__rosa {
  background-color: rgb(186, 82, 157, 0.5);

  span {
    color: rgb(186, 82, 157);
  }
}

.cor__cinza {
  background-color: rgb(206, 212, 218, 0.5);

  span {
    color: rgb(156, 154, 154);
  }
}

.cor__amarelo {
  background-color: #FFB83D;

}

.cor__marrom {
  background-color: rgb(110, 76, 15, 0.5);

  span {
    color: rgb(110, 76, 15);
  }
}

.cor__preto {
  background-color: #2e2c2c;
}

.cor__padrao {
  background-color: rgb(51, 97, 149, 0.9);
}
</style>